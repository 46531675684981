import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_ALERT_LIST,
  ADD_NEW_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  THEME,
  ADD_NEW_MACHINE,
  SET_MACHINE_LIST,
  UPDATE_MACHINE,
  DELETE_MACHINE,
  OPEN_MACHINE_FORM,
  CLOSE_MACHINE_FORM,
  EDIT_SELECTED_MACHINE,
  MACHINE_FORM_TOOGLE_LOADING,
  ADD_NEW_CUSTOMER,
  SET_CUSTOMER_LIST,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  OPEN_CUSTOMER_FORM,
  CLOSE_CUSTOMER_FORM,
  EDIT_SELECTED_CUSTOMER,
  CUSTOMER_FORM_TOOGLE_LOADING,
  ADD_NEW_LAUNDRY,
  SET_LAUNDRY_LIST,
  UPDATE_LAUNDRY,
  DELETE_LAUNDRY,
  OPEN_LAUNDRY_FORM,
  CLOSE_LAUNDRY_FORM,
  EDIT_SELECTED_LAUNDRY,
  LAUNDRY_FORM_TOOGLE_LOADING,
  ADD_NEW_PRODUCT,
  SET_PRODUCT_LIST,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  OPEN_PRODUCT_FORM,
  CLOSE_PRODUCT_FORM,
  EDIT_SELECTED_PRODUCT,
  PRODUCT_FORM_TOOGLE_LOADING,
  ADD_NEW_EQUIPMENT,
  SET_EQUIPMENT_LIST,
  UPDATE_EQUIPMENT,
  DELETE_EQUIPMENT,
  OPEN_EQUIPMENT_FORM,
  CLOSE_EQUIPMENT_FORM,
  EDIT_SELECTED_EQUIPMENT,
  EQUIPMENT_FORM_TOOGLE_LOADING,
  ADD_NEW_MACHINEPROGRAM,
  SET_MACHINEPROGRAM_LIST,
  UPDATE_MACHINEPROGRAM,
  DELETE_MACHINEPROGRAM,
  OPEN_MACHINEPROGRAM_FORM,
  CLOSE_MACHINEPROGRAM_FORM,
  EDIT_SELECTED_MACHINEPROGRAM,
  MACHINEPROGRAM_FORM_TOOGLE_LOADING,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// MACHINES
export const machines = handleActions(
    {
        [SET_MACHINE_LIST]: (state, action) => action.payload,
        [ADD_NEW_MACHINE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_MACHINE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_MACHINE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const machinesForm = handleActions(
    {
        [OPEN_MACHINE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_MACHINE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_MACHINE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [MACHINE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// CUSTOMERS
export const customers = handleActions(
    {
        [SET_CUSTOMER_LIST]: (state, action) => action.payload,
        [ADD_NEW_CUSTOMER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_CUSTOMER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_CUSTOMER]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const customersForm = handleActions(
    {
        [OPEN_CUSTOMER_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_CUSTOMER_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_CUSTOMER]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [CUSTOMER_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// LAUNDRYS
export const laundrys = handleActions(
    {
        [SET_LAUNDRY_LIST]: (state, action) => action.payload,
        [ADD_NEW_LAUNDRY]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_LAUNDRY]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_LAUNDRY]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const laundrysForm = handleActions(
    {
        [OPEN_LAUNDRY_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_LAUNDRY_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_LAUNDRY]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [LAUNDRY_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// PRODUCTS
export const products = handleActions(
    {
        [SET_PRODUCT_LIST]: (state, action) => action.payload,
        [ADD_NEW_PRODUCT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_PRODUCT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_PRODUCT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const productsForm = handleActions(
    {
        [OPEN_PRODUCT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_PRODUCT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_PRODUCT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [PRODUCT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// EQUIPMENTS
export const equipments = handleActions(
    {
        [SET_EQUIPMENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_EQUIPMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EQUIPMENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EQUIPMENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const equipmentsForm = handleActions(
    {
        [OPEN_EQUIPMENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EQUIPMENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EQUIPMENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EQUIPMENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// MACHINEPROGRAMS
export const machinePrograms = handleActions(
    {
        [SET_MACHINEPROGRAM_LIST]: (state, action) => action.payload,
        [ADD_NEW_MACHINEPROGRAM]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_MACHINEPROGRAM]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_MACHINEPROGRAM]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const machineProgramsForm = handleActions(
    {
        [OPEN_MACHINEPROGRAM_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_MACHINEPROGRAM_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_MACHINEPROGRAM]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [MACHINEPROGRAM_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);
