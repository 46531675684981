import {
  HomeOutlined,
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Dashboard",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <UserOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <UserOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: <LoginOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },

  laundrys: {
    key: "laundrys",
    slug: "laundrys",
    to: "/laundrys",
    title: "Lavanderias",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  machines: {
    key: "machines",
    slug: "machines",
    to: "/machines",
    title: "Lavadoras",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  customers: {
    key: "customers",
    slug: "customers",
    to: "/customers",
    title: "Clientes",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  products: {
    key: "products",
    slug: "products",
    to: "/products",
    title: "Productos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  equipments: {
    key: "equipments",
    slug: "equipments",
    to: "/equipments",
    title: "Equipos",
    icon: <HomeOutlined />,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  machinePrograms: {
    key: "machinePrograms",
    slug: "machinePrograms",
    to: "/machinePrograms",
    title: "Programas",
    icon: <HomeOutlined />,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.admin.role],
  },
  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <LogoutOutlined />,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.admin.role, ROLES.user.role],
  },
};
