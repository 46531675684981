import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MACHINEPROGRAM,
    DELETE_MACHINEPROGRAM,
    MACHINEPROGRAM_FORM_TOOGLE_LOADING,
    SET_MACHINEPROGRAM_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MACHINEPROGRAM,
} from "../constants";
import { formatMachinePrograms } from "./settingsActionsUtils";

/* MACHINEPROGRAM LIST */
export const fetchMachinePrograms = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.machinePrograms.list)
            .then((response) => {
                const machinePrograms = formatMachinePrograms(response.data);
                dispatch({
                    type: SET_MACHINEPROGRAM_LIST,
                    payload: keyBy(machinePrograms, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MACHINEPROGRAMS
export const createMachineProgram = (newMachineProgram) => {
    return async (dispatch) => {
        dispatch({ type: MACHINEPROGRAM_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.machinePrograms.create, newMachineProgram)
            .then((response) => {
                const machineProgram = formatMachinePrograms(response.data);
                dispatch({ type: ADD_NEW_MACHINEPROGRAM, payload: machineProgram });
                dispatch({ type: MACHINEPROGRAM_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Programa creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MACHINEPROGRAM_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMachineProgram = (updatedMachineProgram) => {
    return async (dispatch) => {
        dispatch({ type: MACHINEPROGRAM_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.machinePrograms.edit}${updatedMachineProgram && updatedMachineProgram._id}`, updatedMachineProgram)
            .then((response) => {
                const machineProgram = formatMachinePrograms(response.data);
                dispatch({ type: UPDATE_MACHINEPROGRAM, payload: machineProgram });
                dispatch({ type: MACHINEPROGRAM_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Programa actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MACHINEPROGRAM_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMachinePrograms = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MACHINEPROGRAM_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.machinePrograms.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MACHINEPROGRAM, payload: Ids });
                dispatch({ type: MACHINEPROGRAM_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Programa eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MACHINEPROGRAM_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
