/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* MACHINEPROGRAM */
export const SET_MACHINEPROGRAM_LIST = "SET_MACHINEPROGRAM_LIST";
export const ADD_NEW_MACHINEPROGRAM = "ADD_NEW_MACHINEPROGRAM";
export const UPDATE_MACHINEPROGRAM = "UPDATE_MACHINEPROGRAM";
export const DELETE_MACHINEPROGRAM = "DELETE_MACHINEPROGRAM";
export const OPEN_MACHINEPROGRAM_FORM = "OPEN_MACHINEPROGRAM_FORM";
export const CLOSE_MACHINEPROGRAM_FORM = "CLOSE_MACHINEPROGRAM_FORM";
export const EDIT_SELECTED_MACHINEPROGRAM = "EDIT_SELECTED_MACHINEPROGRAM";
export const MACHINEPROGRAM_FORM_TOOGLE_LOADING = "MACHINEPROGRAM_FORM_TOOGLE_LOADING";
/* <---- MACHINEPROGRAM ----> */

/* EQUIPMENT */
export const SET_EQUIPMENT_LIST = "SET_EQUIPMENT_LIST";
export const ADD_NEW_EQUIPMENT = "ADD_NEW_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const OPEN_EQUIPMENT_FORM = "OPEN_EQUIPMENT_FORM";
export const CLOSE_EQUIPMENT_FORM = "CLOSE_EQUIPMENT_FORM";
export const EDIT_SELECTED_EQUIPMENT = "EDIT_SELECTED_EQUIPMENT";
export const EQUIPMENT_FORM_TOOGLE_LOADING = "EQUIPMENT_FORM_TOOGLE_LOADING";
/* <---- EQUIPMENT ----> */

/* PRODUCT */
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const OPEN_PRODUCT_FORM = "OPEN_PRODUCT_FORM";
export const CLOSE_PRODUCT_FORM = "CLOSE_PRODUCT_FORM";
export const EDIT_SELECTED_PRODUCT = "EDIT_SELECTED_PRODUCT";
export const PRODUCT_FORM_TOOGLE_LOADING = "PRODUCT_FORM_TOOGLE_LOADING";
/* <---- PRODUCT ----> */

/* LAUNDRY */
export const SET_LAUNDRY_LIST = "SET_LAUNDRY_LIST";
export const ADD_NEW_LAUNDRY = "ADD_NEW_LAUNDRY";
export const UPDATE_LAUNDRY = "UPDATE_LAUNDRY";
export const DELETE_LAUNDRY = "DELETE_LAUNDRY";
export const OPEN_LAUNDRY_FORM = "OPEN_LAUNDRY_FORM";
export const CLOSE_LAUNDRY_FORM = "CLOSE_LAUNDRY_FORM";
export const EDIT_SELECTED_LAUNDRY = "EDIT_SELECTED_LAUNDRY";
export const LAUNDRY_FORM_TOOGLE_LOADING = "LAUNDRY_FORM_TOOGLE_LOADING";
/* <---- LAUNDRY ----> */

/* CUSTOMER */
export const SET_CUSTOMER_LIST = "SET_CUSTOMER_LIST";
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const OPEN_CUSTOMER_FORM = "OPEN_CUSTOMER_FORM";
export const CLOSE_CUSTOMER_FORM = "CLOSE_CUSTOMER_FORM";
export const EDIT_SELECTED_CUSTOMER = "EDIT_SELECTED_CUSTOMER";
export const CUSTOMER_FORM_TOOGLE_LOADING = "CUSTOMER_FORM_TOOGLE_LOADING";
/* <---- CUSTOMER ----> */

/* MACHINE */
export const SET_MACHINE_LIST = "SET_MACHINE_LIST";
export const ADD_NEW_MACHINE = "ADD_NEW_MACHINE";
export const UPDATE_MACHINE = "UPDATE_MACHINE";
export const DELETE_MACHINE = "DELETE_MACHINE";
export const OPEN_MACHINE_FORM = "OPEN_MACHINE_FORM";
export const CLOSE_MACHINE_FORM = "CLOSE_MACHINE_FORM";
export const EDIT_SELECTED_MACHINE = "EDIT_SELECTED_MACHINE";
export const MACHINE_FORM_TOOGLE_LOADING = "MACHINE_FORM_TOOGLE_LOADING";
/* <---- MACHINE ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
